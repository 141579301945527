<template>
    <div>
        
        <div class="d-flex justify-content-between align-items-center pr-1 mt-1 mb-2">
            <div>
                Attachments <span v-if="topic_attachments.length > 0" class="badge badge-pill badge-light text-dark">{{ topic_attachments.length }}</span>
            </div>
            <div class="attachment-upload-btn">
                <!-- <upload-image @saveUploadImage="saveUploadImage" /> -->
            </div>
        </div>
        <div class="topic-attachments mb-2" v-if="topic_attachments.length > 0">
            <div v-for="item in topic_attachments" :key="item._id" class="attachment"
                v-lazy-container="{
                    selector: 'img',
                    error: 'https://i.giphy.com/media/3oEjI6SIIHBdRxXI40/giphy.webp',
                    loading: 'https://i.giphy.com/media/3oEjI6SIIHBdRxXI40/giphy.webp',
                }"
                >
                <img :src="item.url" :data-src="item.url" alt="" @click="popImage(item)" />
                <div class="detail">
                    <div class="txt">{{ Helpers.shortenFilename(item.attachment.image_name, 12) }}</div>
                    <div>{{ Helpers.formatDateTime(item.attachment.created_at) }}</div>
                </div>
            </div>
        </div>
        <b-modal
            class="full-screen-modal"
            :id="'modalPopoverImageView'"
            v-model="showModal"
            hide-header
            hide-footer
        >
            <button class="closeModalPopoverImageView" @click="closeModalPopoverImageView">
                <feather-icon icon="XIcon" size="12" class="handle" />
            </button>
            <img :src="popoverImage.url" alt="" />
        </b-modal>

    </div>
</template>

<script>
import {
    BButton,
    BFormInput,
    BFormTextarea,
    BFormGroup,
    BAlert,
    BSpinner,
    BFormCheckbox,
    BAvatar,
    BModal,
} from "bootstrap-vue";
import axios from "axios";
import vSelect from "vue-select";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import * as Helpers from '../../../Helpers';
import { VueEditor } from "vue2-editor";
import UploadImage from "../../components/Model/UploadImage.vue";
export default {
    components: {
        UploadImage,
        BButton,
        BFormInput,
        BFormTextarea,
        BFormGroup,
        BAlert,
        BSpinner,
        BFormCheckbox,
        ToastificationContent,
        vSelect,
        VueEditor,
        BAvatar,
        BModal,
    },
    data() {
        return {
            baseVuePath: "@view/src/views/components/themes/",
            baseReactNativePath: "@src/components/themes/",
            vuePath: "@view/src/views/components/themes/",
            reactNativePath: "@src/components/themes/",
            errorMsg: "",
            loading: false,
            alertMessageToast: "",
            record: {
                // default_status: 0,
            },
            avatar: '',
            image_url: '',
            Helpers,
            popoverImage: {},
            showModal: false,
        };
    },
    computed: {
        hasError() {
            return this.errorMsg != "";
        },
    },
    watch: {
    },
    mounted() {
        if (localStorage.getItem("Datauser")) {
            var user_name = JSON.parse(localStorage.getItem("Datauser"));
            if (user_name.user.avatar) {
                this.avatar = user_name.user.avatar;
            }
        }
    },
    methods: {
        popImage(data){
            this.popoverImage = data;
            this.showModal = true;
        },
        closeModalPopoverImageView(){
            this.showModal = false;
        },
        showToast(variant) {
            this.$toast({
                component: ToastificationContent,
                props: {
                    title: "Notification",
                    icon: "BellIcon",
                    text: this.alertMessageToast,
                    variant,
                },
            });
        },
        clearErrorMsg() {
            this.errorMsg = "";
        },
        resetModal() {
            this.errorMsg = "";
        },
        saveUploadImage(url) {
            console.log('url',url);
            this.record.url = url;
            this.saveAttachment();
        },
        async saveAttachment() {
            let status = await this.checkValidation();
            if (!status) {
                return;
            }

            this.loading = true;
            try {
                const resp = await axios.post(process.env.VUE_APP_API_URL + "/topic/attachment/"+this.topic_id,
                    this.record,
                    {
                        headers: {
                            Authorization: "Bearer " + localStorage.getItem("_t"),
                        },
                    });
                this.alertMessageToast = resp.data.message;
                // this.showToast("success");
                this.$emit('emitTopicBoardAttachmentAdded');
                this.loading = false;
                this.record = {};
            } catch (error) {
                this.errorMsg = Helpers.catchFunction(error);
                this.loading = false;
                this.alertMessageToast = this.errorMsg;
                this.showToast("danger");
            }
        },
        checkValidation() {
            if (!this.record.url) {
                this.errorMsg = "Attachment field is required."
                return;
            }
            return "ok";
        },
    },
    props: [
        "manage",
        "topic_id",
        "topic_attachments"
    ],
};
</script>
